import React, { useState } from 'react';

import {
  descendingComparator,
  // addPsychicMixpanelEventButtonTapped,
  // addMixpanelEventDropdownSelected,
} from 'src/utils/commonUtil';
// import { getPsychicInfoMixpanel } from 'src/utils/helpers';
import { PNNotificationStatus, PNOrderType } from 'src/constants/enums';
import { OrderTypeValue, PNCheckMutedAvailable, PNSearchFilter } from 'src/types/objectTypes';
import { UseTableComponent, GetComparatorFunction } from 'types/componentTypes';
import { DEFAULT_MAX_CLIENTS_LIMIT } from 'constants/constants';

// import { selectCurrentUser } from 'selectors/selectors';

export const useTable = (props?: UseTableComponent) => {
  const [showMuted, setShowMuted] = useState<boolean>(props?.defaultMuted || false);
  const [orderBy, setOrderBy] = useState<string>(props?.defaultOrderBy || 'sentDateUTC');
  const [order, setOrder] = useState<OrderTypeValue>(props?.defaultOrder || PNOrderType.DESCENDING);
  const [page, setPage] = useState<number>(props?.defaultCurrentPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(props?.defaultRowsPerPage || 5);
  const [selected, setSelected] = useState<number[]>(props?.defaultSelected || []);
  // const currentUser = useSelector(selectCurrentUser);

  const onSort = (columnId: string) => {
    if (!columnId) return;

    if (!order || orderBy !== columnId) {
      setOrder(PNOrderType.ASCENDING);
      setOrderBy(columnId);
    } else if (order === PNOrderType.ASCENDING) {
      setOrder(PNOrderType.DESCENDING);
      setOrderBy(columnId);
    } else {
      setOrder('');
      setOrderBy(props?.defaultOrderBy || 'customerName');
    }
  };

  const onSelectRow = (id: number, maxLength = DEFAULT_MAX_CLIENTS_LIMIT) => {
    if (selected.includes(id)) {
      const selectedCustomer = selected;
      selectedCustomer.splice(selected.indexOf(id), 1);
      setSelected(selectedCustomer);
    } else if (selected.length < maxLength) {
      setSelected([id, ...selected]);
    }
  };

  const onSelectAllRows = (checked: boolean, newSelected: number[]) => {
    if (checked && selected.length === 4) {
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const onRemoveAllSelectedRows = (callback) => {
    if (callback) {
      callback(null);
    }

    setSelected([]);
  };

  const onChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (count: number) => {
    // addPsychicMixpanelEventDropdownSelected({
    //   dropdown_name: 'rows per page',
    //   dropdown_value: event.target.value,
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicInfoMixpanel(currentUser),
    // });
    setRowsPerPage(count);
    setPage(0);
  };

  const onChangeMuted = (event: React.ChangeEvent<HTMLInputElement>) => {
    // addPsychicMixpanelEventButtonTapped({
    //   button_text: event.target.checked ? 'hide muted enable' : 'hide muted disable',
    //   button_type: 'toggle',
    //   screen_title: 'psychic notifications',
    //   platform: 'psychic desktop app',
    //   ...getPsychicInfoMixpanel(currentUser),
    // });
    setShowMuted(!event.target.checked);
  };

  return {
    showMuted,
    order,
    page,
    setPage,
    orderBy,
    rowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    setOrder,
    setOrderBy,
    onChangePage,
    onChangeMuted,
    onRemoveAllSelectedRows,
    onChangeRowsPerPage,
  };
};

export const getComparator: GetComparatorFunction = (order, orderBy) => (
  order === PNOrderType.ASCENDING
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy)
);

export const emptyRows = (page: number, rowsPerPage: number, arrayLength: number) => (
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0
);

export const applySortFilter = ({
  showMuted, tableData, filterCustomerName, comparator,
}: PNSearchFilter) => tableData
  .map((el, index) => ({ el, index })) // reworking item's structure
  .sort((a: any, b: any) => {
    const order = comparator(a.el, b.el);

    if (order !== 0) return order;

    return a.index - b.index;
  }) // applying sort
  .map((item: any) => item.el) // return item's value
  .filter((item: any) => showMuted || item.notificationStatus !== PNNotificationStatus.MUTED)
  .filter((item: any) => {
    if (filterCustomerName) {
      const name = item?.displayname?.toLowerCase();
      const label = item?.clientLabels?.toLowerCase();

      return (
        name?.includes(filterCustomerName.toLowerCase())
				|| label?.includes(filterCustomerName.toLowerCase())
      );
    }

    return item;
  });

export const applySortFilterForList = ({
  listData, comparator,
}) => listData
  .map((el, index) => ({ el, index })) // reworking item's structure
  .sort((a: any, b: any) => {
    const order = comparator(a.el, b.el);

    if (order !== 0) return order;

    return a.index - b.index;
  }) // applying sort
  .map((item: any) => item.el); // return item's value

export const checkMutedAvailable = ({
  tableData, filterCustomerName,
}: PNCheckMutedAvailable) => {
  if (filterCustomerName) {
    const tableFilterData = tableData.filter((item: any) => {
      const name = item.displayname.toLowerCase();

      return name === filterCustomerName.toLowerCase();
    });

    return tableFilterData.length === 1;
  }

  return tableData.length === 1;
};
