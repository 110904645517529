import React from 'react';
import cn from 'classnames';

import loader from 'src/assets/gif/loader.gif';
import style from 'components/Loader/Loader.module.scss';
import { LoaderInterface } from 'types/componentTypes';

const Loader: React.FC<LoaderInterface> = ({
  withBackground,
  isVisible,
  isThinking,
}) => {
  const styleLoaderBackground = cn(isVisible && withBackground
    ? style.loaderBackground
    : style.hidden);
  const styleLoader = cn(
    isVisible ? style.loader : style.hidden, isThinking && style.thinkingLoader,
  );

  return (
    <>
      <div className={styleLoaderBackground} />
      <div className={styleLoader}>
        <img src={loader} alt="Loading..." />
        {isThinking
        && (
          <div>
            Please wait while we
            update your status.
          </div>
        )}
      </div>
    </>
  );
};

export default Loader;
