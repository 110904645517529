/* eslint-disable max-len */
import React, { Suspense } from 'react';
import 'src/styles/App.scss';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { Route, Router, Switch } from 'react-router-dom';

import Loader from 'components/Loader/Loader';
import routeHistory from 'src/routeHistory';
import { SentryMethods } from 'src/utils/sentryMethods';
import MixpanelActions from 'src/utils/mixpanel';
import MixpanelPsychicActions from 'src/utils/mixpanelPsychicActions';
import MainContainer from 'containers/MainContainer';
import LoginFormContainer from 'containers/LoginFormContainer';
// import ChatAppContainer from 'containers/ChatAppContainer';
// import ClientHubContainer from 'containers/ClientHubContainer';
// import Logout from 'components/Logout';
// import SelectPsychicFormContainer from 'components/Forms/SelectPsychicForm/SelectPsychicFormContainer';
import PrivateRouteSdk from 'src/routes/PrivateRouteSdk';
import PrivateRoute from 'src/routes/PrivateRoute';
import { resizeWindowForAndroid } from 'src/utils/helpers';

const Logout = React.lazy(() => import('components/Logout'));
const SelectPsychicFormContainer = React.lazy(
  () => import('components/Forms/SelectPsychicForm/SelectPsychicFormContainer'),
);
const ClientHubContainer = React.lazy(
  () => import('containers/ClientHubContainer'),
);
const ChatAppContainer = React.lazy(
  () => import('containers/ChatAppContainer'),
);

SentryMethods.init();
MixpanelActions.init();
MixpanelPsychicActions.init();
polyfillCountryFlagEmojis();
resizeWindowForAndroid();

const App = () => (
  <Router history={routeHistory}>
    <MainContainer>
      <Switch>
        <Route exact path="/" component={LoginFormContainer} />
        <Route path="/login" component={LoginFormContainer} />
        <PrivateRouteSdk path="/sdk" />

        <Suspense fallback={<Loader isVisible withBackground />}>
          <Route path="/logout" component={Logout} />
          <PrivateRoute
            path="/psychics"
            component={SelectPsychicFormContainer}
          />
          <PrivateRoute
            path="/conversations/:id?"
            component={ChatAppContainer}
          />
          <PrivateRoute path="/clienthub" component={ClientHubContainer} />
        </Suspense>
      </Switch>
    </MainContainer>
  </Router>
);

export default App;
