import {
  SET_READING_CUSTOMERS_LIST,
  SEND_NOTIFICATION_TO_CUSTOMER,
  SET_PSYCHIC_NOTIFICATIONS_ACTIVE,
  SET_PSYCHIC_ALL_NOTIFICATIONS_LIST,
  SET_NOTIFICATION_CUSTOMER_DETAIL,
  CLEAR_PSYCHIC_NOTIFICATION,
  SET_NOTES_HISTORY,
  SET_ACTIVE_SCREEN,
  SET_PSYCHIC_NOTE_CONFIGURABLE_DATA,
  UPDATE_CLIENT_NOTES,
  SET_PSYCHIC_NOTES_STATS,
  SET_TODAY_CUSTOMER_NOTE_COUNT,
  SET_ALL_PSYCHIC_NOTIFICATION_MUTE,
  SET_CURRENT_PCN_TAB,
  SET_PRIORITY_ACTION_LIST,
  SET_PRIORITY_ACTION_COUNT,
  SET_PRIORITY_ACTION_MESSAGES,
  SET_CLIENT_NOTES_DRAFT_TOAST,
  SET_CLIENT_LABELS_LIST,
  SET_CLIENT_AUTO_COMPLETE_DROP_DOWN_LIST,
} from 'actions/actionsTypes';
import { PSYCHIC_NOTE_TAB_ID } from 'constants/constants';
import { PNTabs } from 'constants/enums';
import {
  ReducerAction,
  PsychicNotificationsType,
} from 'types/objectTypes';

const initialState: PsychicNotificationsType = {
  isNotificationActive: true,
  customerList: [],
  clientLabelsList: [],
  notificationList: [],
  sendCustomerDetail: null,
  sendNotificationDetail: [],
  notificationStatistics: {
    reactionPercentage: '0%',
    totalNotificationSent: 0,
    customerReactionList: [],
    engagementGoal: {
      clientPool: 0,
      psychicGoal: 0,
      psychicSentCount: 0,
      periodicDate: null,
    },
  },
  notesHistory: {
    customerNotesCount: 0,
    customerLastSendDateUTC: '',
    isClientAccessToPsychicNotes: true,
    isCustomerOnCall: false,
    clientLabels: [],
  },
  notesHistoryList: [],
  activeScreen: PSYCHIC_NOTE_TAB_ID.ALL_NOTE,
  psychicNoteConfigurableData: {
    clientLastNoteLockedCycle: 24,
    clientSendNoteLimit: 3,
    psychicMaxNotificationsSend: 5,
    psychicGamifyFeatureEnabled: false,
    psychicNotificationRefreshEveryMins: 1,
    psychicNotesSystemMessages: [],
  },
  isAllPsychicNotificationMute: false,
  priorityActionCount: 0,
  currentPCNTab: PNTabs.ALL_NOTES_TAB,
  priorityActionList: [],
  priorityActionMessages: [],
  clientNotesDraftToast: {
    isToastVisible: false,
    draftedCutomerName: '',
  },
};

const psychicNotificationsReducer = (
  state: PsychicNotificationsType = initialState,
  { type, payload }: ReducerAction,
) => {
  switch (type) {
    case SET_PSYCHIC_NOTIFICATIONS_ACTIVE: {
      return { ...state, isNotificationActive: payload };
    }
    case SET_PSYCHIC_NOTES_STATS: {
      return { ...state, notificationStatistics: payload };
    }
    case SET_PSYCHIC_ALL_NOTIFICATIONS_LIST: {
      return { ...state, notificationList: payload };
    }
    case SET_READING_CUSTOMERS_LIST: {
      return { ...state, customerList: payload };
    }
    case SEND_NOTIFICATION_TO_CUSTOMER: {
      return { ...state, sendNotificationDetail: payload };
    }
    case SET_NOTIFICATION_CUSTOMER_DETAIL: {
      return { ...state, sendCustomerDetail: payload };
    }
    case SET_NOTES_HISTORY: {
      return { ...state, notesHistory: payload };
    }
    case SET_ACTIVE_SCREEN: {
      return { ...state, activeScreen: payload };
    }
    case SET_PSYCHIC_NOTE_CONFIGURABLE_DATA: {
      return { ...state, psychicNoteConfigurableData: payload };
    }
    case UPDATE_CLIENT_NOTES: {
      return { ...state, notesHistoryList: payload };
    }
    case SET_TODAY_CUSTOMER_NOTE_COUNT: {
      return {
        ...state,
        notesHistory: {
          ...state.notesHistory,
          customerNotesCount: payload,
        },
      };
    }
    case SET_ALL_PSYCHIC_NOTIFICATION_MUTE: {
      return { ...state, isAllPsychicNotificationMute: payload };
    }
    case SET_CURRENT_PCN_TAB: {
      return { ...state, currentPCNTab: payload };
    }
    case SET_PRIORITY_ACTION_LIST: {
      return { ...state, priorityActionList: payload };
    }
    case SET_PRIORITY_ACTION_COUNT: {
      return { ...state, priorityActionCount: payload };
    }
    case SET_PRIORITY_ACTION_MESSAGES: {
      return { ...state, priorityActionMessages: payload };
    }
    case SET_CLIENT_NOTES_DRAFT_TOAST: {
      return { ...state, clientNotesDraftToast: payload };
    }
    case SET_CLIENT_LABELS_LIST: {
      return { ...state, clientLabelsList: payload };
    }
    case SET_CLIENT_AUTO_COMPLETE_DROP_DOWN_LIST: {
      return { ...state, clientAutoCompleteDropDownList: payload };
    }
    case CLEAR_PSYCHIC_NOTIFICATION: {
      return initialState;
    }

    default:
      return state;
  }
};

export default psychicNotificationsReducer;
