import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';

import { PrivateRouterInterface } from 'types/componentTypes';
import {
  restartChat as _restartChat,
  setView as _setView,
} from 'actions/appActions';
import LoaderContainer from 'components/Loader';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  CUSTOMER_ID_STORAGE,
  PSYCHIC_ID_STORAGE,
  PASSWORD_STORAGE,
  TOKEN_AUTH_STORAGE,
  TOKEN_BEARER,
  TOKEN_TWILIO_STORAGE,
} from 'constants/constants';
import {
  setAuthToken as _setAuthToken,
  setTwilioToken as _setTwilioToken,
  setBearerToken as _setBearerToken,
} from 'actions/authActions';
import { View } from 'constants/enums';
import {
  setCustomerId as _setCustomerId,
  setEncryptedPassword as _setEncryptedPassword,
} from 'actions/chatActions';
import {
  selectAuthToken,
  selectCommand,
  selectIsLoading,
  selectTwilioToken,
} from 'selectors/selectors';
import useBindDispatch from 'src/hooks/useBindDispatch';

const PrivateRoute: React.FC<PrivateRouterInterface> = React.memo(({
  component: Component,
  ...rest
}) => {
  useSelector(selectTwilioToken);
  useSelector(selectIsLoading);

  const token = useSelector(selectAuthToken);
  const command = useSelector(selectCommand);

  const restartChat = useBindDispatch(_restartChat);
  const setAuthToken = useBindDispatch(_setAuthToken);
  const setBearerToken = useBindDispatch(_setBearerToken);
  const setTwilioToken = useBindDispatch(_setTwilioToken);
  const setView = useBindDispatch(_setView);
  const setCustomerId = useBindDispatch(_setCustomerId);
  const setEncryptedPassword = useBindDispatch(_setEncryptedPassword);

  const [isAuthenticated, setIsAuthenticated] = useState<any>(null);
  const { search, pathname } = useLocation();
  const params = qs.parse(search);

  useEffect(() => {
    const isPsychics = pathname.includes('/psychics');
    const isClientHub = pathname.includes('/clienthub');

    const [
      tokenAuthFromStorage,
      tokenTwilioFromStorage,
      bearerToken,
      psychicId,
      psychicPassword,
      customerId,
    ] = LocalStorage.getItems([
      TOKEN_AUTH_STORAGE,
      TOKEN_TWILIO_STORAGE,
      TOKEN_BEARER,
      PSYCHIC_ID_STORAGE,
      PASSWORD_STORAGE,
      CUSTOMER_ID_STORAGE,
    ]);

    if (!isPsychics && psychicPassword) {
      setEncryptedPassword(psychicPassword);
    }

    if (!tokenAuthFromStorage) {
      setIsAuthenticated(false);

      return;
    }

    if (!token && tokenAuthFromStorage && !command && !isPsychics && !isClientHub) {
      restartChat({
        chatId: rest?.computedMatch?.params?.id,
        tokenAuth: tokenAuthFromStorage,
        tokenTwilio: tokenTwilioFromStorage,
        bearerToken,
        view: params.view,
        psychicId,
        customerId,
      });
    }

    if ((isPsychics || isClientHub) && !token) {
      setAuthToken(tokenAuthFromStorage);
      setView(View.CUSTOMER);
      setCustomerId(customerId);

      if (isPsychics) {
        setTwilioToken(tokenTwilioFromStorage);
      } else {
        setBearerToken(bearerToken);
      }
    }

    if (tokenAuthFromStorage) {
      setIsAuthenticated(true);
    }
  }, [token, pathname]);

  if (isAuthenticated === null) {
    return <LoaderContainer withBackground />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? <Component {...props} /> : (
        <Redirect to={{
          pathname: '/login',
          search,
        }}
        />
      ))}
    />
  );
});

export default PrivateRoute;
