import {
  put,
  call,
  select,
  takeEvery,
  takeLatest,
  putResolve,
  all,
} from 'redux-saga/effects';
import { MixpanelEvents, View } from 'extracted-chat-components/enums';

import { API } from 'src/utils/api';
import {
  setLoadingState,
  handleRequestError,
  setConfirmationScreen,
  setNotificationUnreadCount,
  setActivePage,
  setIsPsychicNotificationsEnable,
} from 'actions/appActions';
import {
  setReadingCustomersList,
  getReadingCustomersList,
  setClientAutoCompleteDropDownList,
  updateNoteReadStatus,
  sendNotificationToCustomer,
  setPsychicAllNotificationsList,
  getClientNoteHistory,
  setNotesHistory,
  updateClientNotes,
  getPsychicAllNotificationsList,
  sendBlockedContentToCs,
  setPsychicNotesStats,
  updateCustomerNotification,
  updateNotificationStatus,
  setPsychicNoteConfigurableData,
  updateTodayCustomerNotesCount,
  setAllPsychicNotificationMute,
  unMuteAllPsychicNotification,
  setNotificationCustomerDetail,
  setCurrentPCNTab,
  getPriorityActionList,
  setPriorityActionList,
  setPriorityActionCount,
  setClientLabelsList,
  updateClientLabelsList,
} from 'actions/psychicNotifications';
import {
  GET_READING_CUSTOMERS_LIST,
  UPDATE_REACTION_READ_STATUS,
  SEND_NOTIFICATION_TO_CUSTOMER,
  GET_PSYCHIC_ALL_NOTIFICATIONS_LIST,
  GET_CLIENT_NOTE_HISTORY,
  CHECK_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT,
  SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_PSYCHIC,
  SEND_BLOCKED_CONTENT_TO_CS,
  UPDATE_CUSTOMER_NOTIFICATION,
  SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_CUSTOMER,
  UPDATE_NOTIFICATION_STATUS,
  UNMUTE_ALL_PSYCHIC_NOTIFICATION,
  SET_CURRENT_PCN_TAB,
  GET_PRIORITY_ACTION_LIST,
  GET_PRIORITY_ACTION_COUNT,
  UPDATE_CLIENT_LABELS,
} from 'actions/actionsTypes';
import {
  selectCurrentUser,
  selectCustomerId,
  selectExtId,
  selectUserAgent,
  selectView,
} from 'selectors/selectors';
import {
  getPlatform,
  descriptionFromError,
  setCookieWithExpireDay,
  addPsychicMixpanelEventContentSent,
  removeActionFromList,
  getValuesFromList,
  extractValuesFromArray,
} from 'src/utils/commonUtil';
import { SentryMethods } from 'src/utils/sentryMethods';
import {
  PAGE_ID,
  PSYCHIC_ID_STORAGE,
  IS_MOBILE_INTEGRATION,
  PRIORITY_ACTION_COMPLETED_KEY,
} from 'constants/constants';
import {
  selectNotesHistoryList,
  selectSendCustomerDetail,
  selectPsychicNoteConfigurableData,
  selectPsychicNotificationDetail,
  selectPriorityActionList,
  selectNotificationStatistics,
} from 'selectors/psychicNotificationsSelectors';
import { LocalStorage } from 'src/utils/storageHandler';
import {
  ClientHubSource,
  DefaultOrderBy,
  NotesSentLocation,
  PNOrderType,
  PNTabs,
} from 'constants/enums';
import { getPsychicInfoMixpanel } from 'src/utils/helpers';
import MixpanelActions from 'src/utils/mixpanel';
import { applySortFilter, getComparator } from 'src/hooks/useTable';

import {
  handleSetUpPsychicNotesByLink,
  handleSetUpPsychicNotesByLinkForCustomer,
} from './setUpPsychicNoteByLinkSaga';

export function* fetchPsychicNotesState() {
  try {
    const { extId } = yield select(selectCurrentUser);
    const id = LocalStorage.getItem(PSYCHIC_ID_STORAGE) || extId;
    const { data } = yield call(API.Psychic.getPsychicNotesStats, id);

    if (data) {
      yield putResolve(setPsychicNotesStats(data));
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* fetchAllNotificationsList(
  { payload }: ReturnType<typeof getPsychicAllNotificationsList>,
) {
  const { showLoader } = payload;

  try {
    if (showLoader) {
      yield put(setLoadingState(true));
    }

    const view = yield select(selectView);

    if (view === View.CUSTOMER) {
      const customerId = yield select(selectCustomerId);

      const { psychicNotificationsList, allPsychicsNotificationAlert } = yield call(
        API.Customer.getCustomerNotificationList, customerId,
      );

      // allPsychicsNotificationAlert = true means, all psychic are not muted.
      // allPsychicsNotificationAlert = false means, all psychic are muted by customer.
      yield putResolve(setAllPsychicNotificationMute(!allPsychicsNotificationAlert));

      if (psychicNotificationsList) {
        yield putResolve(setPsychicAllNotificationsList(psychicNotificationsList));
      } else {
        MixpanelActions.track(MixpanelEvents.SCREEN_VIEWED, {
          screen_name: 'inbox',
          screen_title: 'inbox',
        });

        MixpanelActions.track(MixpanelEvents.PROMPT_DELIVERED, {
          prompt_text: 'If you’d like to send a note to a psychic you’ve recently read with, tap the ‘Send a Note’ button above.',
          prompt_type: 'psychic notes empty state',
          screen_title: 'inbox',
        });
      }

      const version = yield call(API.App.getVersionDetail);
      const {
        clientLastNoteLockedCycle,
        clientSendNoteLimit,
        psychicMaxNotificationsSend,
        psychicNotesSystemMessages,
        psychicNotificationRefreshEveryMins,
        psychicNotificationsEnabled,
        psychicGamifyFeatureEnabled,
      } = version;

      yield all([
        putResolve(setIsPsychicNotificationsEnable(psychicNotificationsEnabled)),
        putResolve(setPsychicNoteConfigurableData({
          clientLastNoteLockedCycle,
          clientSendNoteLimit,
          psychicMaxNotificationsSend,
          psychicGamifyFeatureEnabled,
          psychicNotificationRefreshEveryMins,
          psychicNotesSystemMessages: JSON.parse(psychicNotesSystemMessages),
        })),
      ]);
    } else {
      const { extId } = yield select(selectCurrentUser);
      const { psychicGamifyFeatureEnabled } = yield select(selectPsychicNoteConfigurableData);
      const id = LocalStorage.getItem(PSYCHIC_ID_STORAGE) || extId;
      const { data } = yield call(
        API.Psychic.getPsychicAllNotificationsList,
        id,
        psychicGamifyFeatureEnabled,
      );

      if (data) {
        yield putResolve(setPsychicAllNotificationsList(data.psychicNotificationList));
      }

      if (psychicGamifyFeatureEnabled) {
        yield call(fetchPsychicNotesState);
      } else if (data.notificationStatistics) {
        yield putResolve(setPsychicNotesStats(data.notificationStatistics));
      }

      yield put(setActivePage(PAGE_ID.PSYCHIC_INBOX));
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* fetchReadingCustomersList(
  { payload }: ReturnType<typeof getReadingCustomersList>,
) {
  const { showLoader } = payload;

  try {
    if (showLoader) {
      yield put(setLoadingState(true));
    }

    const view = yield select(selectView);

    if (view === View.CUSTOMER) {
      const customerId = yield select(selectCustomerId);

      const { psychicNotificationsPsychicList } = yield call(
        API.Customer.getReadingPsychicList, customerId,
      );

      if (psychicNotificationsPsychicList) {
        yield putResolve(setReadingCustomersList(psychicNotificationsPsychicList));
      }
    } else {
      const { extId } = yield select(selectCurrentUser);
      const { data } = yield call(
        API.Psychic.getReadingCustomersList,
        extId,
      );

      if (data) {
        const { customerList = [], clientLabels } = data;

        const clientListByDisplayName = customerList?.map(
          ({ displayname, zodiacSign }) => ({ group: 'Client List', label: displayname, zodiacSign }),
        ).filter((obj1, i, arr) => arr.findIndex((obj2) => (obj2.label === obj1.label)) === i);

        const clientListByLabel = [
          ...new Set(
            customerList
              ?.flatMap(({ clientLabels }) => clientLabels?.split(','))
              ?.filter((item) => item),
          ),
        ]?.map((label) => ({ group: 'Label', label }));
        const clientListAutocompleteDD = [...clientListByDisplayName, ...clientListByLabel];

        yield put(setClientAutoCompleteDropDownList(clientListAutocompleteDD));
        yield put(setReadingCustomersList(customerList));
        yield put(
          setClientLabelsList(
            clientLabels.sort((a, b) => a.clientLabelsId - b.clientLabelsId),
          ),
        );
      }
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* updateUnreadReactionsStatusByIds(
  { pcComId }: ReturnType<typeof updateNoteReadStatus>,
) {
  try {
    const { extId } = yield select(selectCurrentUser);

    if (pcComId) {
      const { data } = yield call(
        API.Psychic.updateNoteReadStatus,
        extId,
        pcComId,
      );

      if (data) {
        const { unreadNotificationsCount } = data;
        yield put(setNotificationUnreadCount(unreadNotificationsCount));
      }
    }
  } catch (e) {
    console.error(e);
    yield call(SentryMethods.captureException, e);
  }
}

function* handleSendNotificationToPsychic(payload) {
  const { noteDetails } = payload;
  const { customerRefId } = yield select(selectCurrentUser);
  const sendCustomerDetail = yield select(selectSendCustomerDetail);
  const notesList = yield select(selectNotesHistoryList);

  const message = {
    messageBody: noteDetails.messageBody,
    sendBy: noteDetails.sendBy,
    createdDateUTC: new Date(),
    extId: noteDetails.extId,
    customerId: customerRefId,
    pcComId: 0,
    isRead: 1,
  };

  const payloadForCustomer = {
    ExtId: noteDetails.extId,
    MessageBody: noteDetails.messageBody,
    SendBy: noteDetails.sendBy,
    Source: ClientHubSource.WEB,
    SendToCustomerList: [{
      CallRecId: 0,
      CustomerId: customerRefId,
      CustomerTypeId: 0,
      IsPsychicNotificationsEmailEnabled: true,
      IsPsychicNotificationsEnabled: false,
      NotificationStatusId: 0,
      ZodiacSignId: 0,
    }],
  };
  const res = yield call(
    API.Customer.sendNotesToPsychic, payloadForCustomer,
  );

  if (res.isSuccessful || res.isSuccess) {
    message.pcComId = res.pcComId;
    yield put(updateClientNotes([
      ...notesList,
      message,
    ]));
    yield put(setConfirmationScreen(true));

    const customerDetail = {
      ...sendCustomerDetail[0],
      pcComId: res.pcComId,
    };
    yield put(setNotificationCustomerDetail([customerDetail]));

    if (res.todayCustomerNotesCount) {
      yield put(updateTodayCustomerNotesCount(res.todayCustomerNotesCount));
    }
  }
}

function* handleSendNotificationToCustomer(payload) {
  const notesList = yield select(selectNotesHistoryList);
  const userAgent = yield select(selectUserAgent);
  const currentUser = yield select(selectCurrentUser);
  const { extId } = yield select(selectCurrentUser);
  const isMobileIntegration = LocalStorage.getItem(IS_MOBILE_INTEGRATION);

  const { noteDetails } = payload;
  const data = {
    messageBody: noteDetails.messageBody,
    sendBy: noteDetails.sendBy,
    source: isMobileIntegration ? ClientHubSource.APP : ClientHubSource.ELECTRON,
    notesSentLocation: noteDetails?.notesSentLocation,
    extId,
  };
  const res = yield call(
    API.Psychic.sendNotificationToCustomer, {
      sendToCustomerList: noteDetails.sendToCustomerList,
      ...data,
    },
  );

  if (res.isSuccessful || res.isSuccess) {
    addPsychicMixpanelEventContentSent({
      content_type: 'psychic note',
      content_id: res.data.pcComHistoryId,
      customerId: noteDetails.sendToCustomerList[0].customerId,
      user_type: noteDetails.sendToCustomerList[0].customerType,
      chatSource: getPlatform(userAgent, 'mixpanel'),
      chat_platform: 'chat2.0',
      ...getPsychicInfoMixpanel(currentUser),
    });

    if (noteDetails?.notesSentLocation !== NotesSentLocation.ComposeScreen) {
      yield put(updateClientNotes([
        ...notesList,
        {
          createdDateUTC: new Date(),
          isRead: 0,
          ...data,
        },
      ]));
    }

    const priorityActionList = yield select(selectPriorityActionList);
    const {
      isPriorityAction,
      newList,
    } = removeActionFromList(priorityActionList, noteDetails.sendToCustomerList);

    if (isPriorityAction) {
      yield put(setPriorityActionList(newList));

      if (!newList.length) {
        setCookieWithExpireDay(PRIORITY_ACTION_COMPLETED_KEY, 'true', 1);
      }
    }

    yield put(setConfirmationScreen(true));
  }
}

function* handleSendNotification(
  { payload }: ReturnType<typeof sendNotificationToCustomer>,
) {
  const view = yield select(selectView);

  try {
    yield put(setLoadingState(true));

    if (view === View.CUSTOMER) {
      yield call(handleSendNotificationToPsychic, payload);
    } else {
      yield call(handleSendNotificationToCustomer, payload);
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: `/conversations?view=${view}`,
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleSendNoteToCs({ payload }: ReturnType<typeof sendBlockedContentToCs>) {
  try {
    const { extId, friendlyName } = yield select(selectCurrentUser);

    yield call(API.Psychic.sendingBlockedContentToCS, extId, friendlyName, payload);
  } catch (e) {
    console.error(e);
  }
}

export function* checkNotificationReactionUnreadCount() {
  try {
    const { extId } = yield select(selectCurrentUser);
    const { data } = yield call(API.Psychic.getPsychic, extId);

    if (data) {
      yield put(setNotificationUnreadCount(data.unreadNotificationsCount));
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  }
}

export function* fetchClientNoteHistory(
  { payload }: ReturnType<typeof getClientNoteHistory>,
) {
  const { pcComId, showLoader } = payload;
  try {
    if (showLoader) {
      yield put(setLoadingState(true));
    }

    const view = yield select(selectView);
    const user = yield select(selectCurrentUser);
    let extId;
    let isCustomerOnCall = false;

    if (view === View.CUSTOMER) {
      extId = yield select(selectExtId);

      const { result } = yield call(
        API.Customer.getCustomerInfo,
        { customerRefId: user.customerRefId, workerRefId: extId },
      );

      isCustomerOnCall = result?.nextAction?.reason === 'PinInUse';
    } else {
      extId = user.extId;
    }

    const { data } = yield call(
      API.Psychic.getPsychicNotificationDetails, extId, pcComId,
    );

    if (data) {
      const {
        notesHistory,
        clientLabelsList,
        todayCustomerNotesCount,
        customerLastSendDateUTC: lastSentDate,
        isClientAccessToPsychicNotes,
      } = data;

      const isAccess = typeof isClientAccessToPsychicNotes === 'boolean' ? isClientAccessToPsychicNotes : true;

      yield put(setNotesHistory({
        customerNotesCount: todayCustomerNotesCount,
        customerLastSendDateUTC: lastSentDate || '',
        isClientAccessToPsychicNotes: isAccess,
        isCustomerOnCall,
        clientLabels: clientLabelsList,
      }));
      yield put(updateClientNotes(notesHistory));
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* updateNotification(
  { payload }: ReturnType<typeof updateCustomerNotification>,
) {
  const {
    action,
    pcComId,
    pcComHistoryId,
    reactionTypeId,
    handleOnSelect = () => {},
  } = payload;

  try {
    if (handleOnSelect) {
      yield put(setLoadingState(true));
    }

    const statusPayload = {
      action,
      pcComId,
      pcComHistoryId,
      reactionTypeId,
    };

    yield call(API.Customer.updateCustomerNotification, statusPayload);

    yield put(getClientNoteHistory(pcComId));

    if (typeof handleOnSelect === 'function') {
      yield call(handleOnSelect);
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/clienthub?view=customer',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
    yield put(setLoadingState(false));
  }
}

export function* updateNotificationStatusWorker(
  { payload }: ReturnType<typeof updateNotificationStatus>,
) {
  yield put(setLoadingState(true));
  const {
    customerId,
    extId,
    psychicNotificationsAlert,
    setTooltipVisible,
    setMuted,
  } = payload;

  try {
    const statusPayload = {
      customerId,
      extId,
      psychicNotificationsAlert,
      modifiedBy: '',
    };

    const res = yield call(API.Customer.mutePsychic, statusPayload);

    if (res.isSuccess) {
      setMuted(!psychicNotificationsAlert);
      setTooltipVisible(false);
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/clienthub?view=customer',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* updateAllPsychicNotificationMute(
  { payload }: ReturnType<typeof unMuteAllPsychicNotification>,
) {
  yield put(setLoadingState(true));

  const {
    customerEncId: customerId,
    unMuteAllPsychic: allPsychicsNotificationAlert,
  } = payload;

  try {
    const statusPayload = {
      extId: 0,
      customerId,
      allPsychicsNotificationAlert,
      psychicNotificationsAlert: false,
    };
    const res = yield call(API.Customer.mutePsychic, statusPayload);

    if (res.isSuccess) {
      yield put(setAllPsychicNotificationMute(!allPsychicsNotificationAlert));
      yield put(getPsychicAllNotificationsList(true));
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/clienthub?view=customer',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleOnChangeCurrentPCNTab(
  { payload }: ReturnType<typeof setCurrentPCNTab>,
) {
  const {
    notificationList,
    customerList,
  } = yield select(selectPsychicNotificationDetail);

  if (payload === PNTabs.CUSTOMER_LIST_TAB) {
    yield put(getReadingCustomersList(!customerList.Length));
  } else if (payload === PNTabs.ALL_NOTES_TAB) {
    yield put(getPsychicAllNotificationsList(!notificationList.Length));
  } else {
    yield put(getPriorityActionList());
  }
}

export function* fetchPriorityActionList(
  { payload }: ReturnType<typeof getPriorityActionList>,
) {
  const { showLoader } = payload;

  try {
    if (showLoader) {
      yield put(setLoadingState(true));
    }

    const { extId } = yield select(selectCurrentUser);
    const id = extId || LocalStorage.getItem(PSYCHIC_ID_STORAGE);
    const { psychicGamifyFeatureEnabled } = yield select(selectPsychicNoteConfigurableData);
    const { data } = yield call(API.Psychic.getPriorityActionsList, id);
    const notificationStatistics = yield select(selectNotificationStatistics);

    if (!notificationStatistics.totalNotificationSent && showLoader) {
      if (psychicGamifyFeatureEnabled) {
        yield call(fetchPsychicNotesState);
      } else {
        yield call(getPsychicAllNotificationsList, false);
      }
    }

    if (data) {
      const { psychicPriorityActionList, priorityActionsCount } = data;
      yield put(setPriorityActionCount(priorityActionsCount));
      const sortedList = applySortFilter({
        showMuted: false,
        tableData: psychicPriorityActionList,
        filterCustomerName: '',
        comparator: getComparator(PNOrderType.DESCENDING, DefaultOrderBy.PRIORITY_ACTION_TAB),
      });
      yield put(setPriorityActionList(sortedList));

      if (showLoader) {
        yield put(setActivePage(PAGE_ID.PSYCHIC_INBOX));
      }
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* fetchPriorityActionCount(
  { payload }: ReturnType<typeof getPriorityActionList>,
) {
  const { showLoader } = payload;

  try {
    if (showLoader) {
      yield put(setLoadingState(true));
    }

    const { extId } = yield select(selectCurrentUser);
    const id = extId || LocalStorage.getItem(PSYCHIC_ID_STORAGE);
    const { data } = yield call(API.Psychic.getPriorityActionsList, id);

    if (data) {
      const { priorityActionsCount } = data;
      yield put(setPriorityActionCount(priorityActionsCount));
    }
  } catch (e) {
    console.error(e);

    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* handleUpdateClientLabels(
  { payload }: ReturnType<typeof updateClientLabelsList>,
) {
  try {
    yield put(setLoadingState(true));
    const { customer, clientLabels, callback } = payload;
    const { customerId, pCComID } = customer;
    const labelsPayload = {
      clientLabels: getValuesFromList(clientLabels, 'clientLabelsId'),
      pcComId: pCComID,
    };
    const { isSuccessful } = yield call(API.Psychic.updateClientLabels, labelsPayload);

    if (isSuccessful) {
      const labels = extractValuesFromArray(clientLabels, 'clientLabelsText');
      callback(customerId, labels);
    }
  } catch (e) {
    console.error(e);
    const requestErrorPayload = {
      redirectPath: '/conversations?view=psychic',
      isInvalidToken: false,
      errorText: e?.message,
      description: descriptionFromError(e),
    };
    yield call(SentryMethods.captureException, e);
    yield put(handleRequestError(requestErrorPayload));
  } finally {
    yield put(setLoadingState(false));
  }
}

export function* psychicNotificationsSagaWatcher() {
  yield takeEvery(GET_READING_CUSTOMERS_LIST, fetchReadingCustomersList);
  yield takeLatest(SEND_NOTIFICATION_TO_CUSTOMER, handleSendNotification);
  yield takeEvery(UPDATE_REACTION_READ_STATUS, updateUnreadReactionsStatusByIds);
  yield takeEvery(GET_PSYCHIC_ALL_NOTIFICATIONS_LIST, fetchAllNotificationsList);
  yield takeEvery(GET_CLIENT_NOTE_HISTORY, fetchClientNoteHistory);
  yield takeEvery(CHECK_PSYCHIC_NOTIFICATIONS_UNREAD_COUNT, checkNotificationReactionUnreadCount);
  yield takeEvery(SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_PSYCHIC, handleSetUpPsychicNotesByLink);
  yield takeEvery(
    SET_UP_PSYCHIC_NOTES_BY_LINK_FOR_CUSTOMER,
    handleSetUpPsychicNotesByLinkForCustomer,
  );
  yield takeEvery(SEND_BLOCKED_CONTENT_TO_CS, handleSendNoteToCs);
  yield takeEvery(UPDATE_CUSTOMER_NOTIFICATION, updateNotification);
  yield takeEvery(UPDATE_NOTIFICATION_STATUS, updateNotificationStatusWorker);
  yield takeEvery(UNMUTE_ALL_PSYCHIC_NOTIFICATION, updateAllPsychicNotificationMute);
  yield takeEvery(SET_CURRENT_PCN_TAB, handleOnChangeCurrentPCNTab);
  yield takeEvery(GET_PRIORITY_ACTION_LIST, fetchPriorityActionList);
  yield takeEvery(GET_PRIORITY_ACTION_COUNT, fetchPriorityActionCount);
  yield takeEvery(UPDATE_CLIENT_LABELS, handleUpdateClientLabels);
}
